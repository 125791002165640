.button-container {
    display: flex;
    justify-content: center;
    /* background-color: rgba(255, 145, 0, 0.164); */
  }
  
  .red-button {
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
  }
  