.contact-page{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    
    /* background-color: rgba(255, 145, 0, 0.164); */
}
.about{
    padding: 2cm;
    padding-top: 2cm;
    /* background-color: rgba(255, 145, 0, 0.164); */
}
.about h1{
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
}
.imga{
    display: flex;
    justify-content: center;
    padding-bottom: 1cm;
    height: 300px;
    width: 300px;
}
.contact-page h2{
    font-family: Georgia, 'Times New Roman', Times, serif;
    
}
.contact-page {
    max-width: 100%;
    padding-left: 2cm;
    padding-bottom: 2cm;
    padding-right: 2cm;
  }
  
  h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  ul.contact-info {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  ul.contact-info li {
    font-size: 18px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  ul.contact-info li i {
    margin-right: 10px;
    font-size: 24px;
  }
  
  .btn {
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 20px;
  }
  
  /* .btn-facebook {
    background-color: #3b5998;
    color: #fff;
  }
  
  .btn-instagram {
    background-color: #e1306c;
    color: #fff;
  }
  
  .btn-whatsapp {
    background-color: #00A884;
    color: #fff;
  } */
  
  .qr-code {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  iframe {
    margin-top: 20px;
    border: none;
  }
  @media (max-width: 768px) {
  .imga img {
    max-width: 100%;
    height: auto;
  }
}
.aboutmain{
  text-align: center;
  font-size: 40px !important;
}
.housechoices{
  display:flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.card-img {
  max-height: 250px;
  object-fit: cover;
}
.large-badge {
  font-size: 1.2rem;
  padding: 0.5rem;
}
.center-badge {
  display: flex;
  justify-content: center;
}
.map{
  display: flex;
  justify-content: space-evenly;
  padding-left: 1cm;
  padding-right: 1cm;
  padding-bottom: 3cm;
  /* background-color: rgba(255, 145, 0, 0.164); */
  flex-wrap: wrap;
}
.h4a{
  text-align: center;
}
@media (max-width: 768px) {
  .about {
    padding: 10px;
   
  }
}
@media (max-width: 767px) {
  .map iframe {
    width: 100%;
    margin: 10px 0;
  }
}
.bodytc{
  font-size: 20px;
}