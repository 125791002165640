.footer {
  /* position: sticky; */
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #f5a300d3!important;
  }
  
  .footer .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #f5a300d3!important;
  
  }
  
  .footer .text-muted {
    font-size: 14px;
  }
  .footer .text-muted {
    color: #fff !important;
    font-weight: bold !important;
  }