
  
  .slide img {
    width: 100%;
    height: 100%;
  }
  
  .current {
    opacity: 1;
    transform: translateX(0);
  }
  
  .content {
    position: absolute;
    top: 23rem;
    left: 5rem;
    opacity: 0;;
    width: 50%;
    color: #fff;
    padding: 3rem;
    background: rgba(223, 215, 215, 0.671);
    animation: slide-up 1s ease 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    visibility: hidden;
  }
  
  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 23rem;
    }
    100% {
      visibility: visible;
      top: 17rem;
    }
  }
  
  @media screen and (max-width: 600px) {
    .content {
      width: 80%;
    }
  }
  
  .content > * {
    color: #000000;
    margin-bottom: 1rem;
  }
  
  .current .content {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.5s ease;
  }
  
  .arrow {
    border: 2px solid white;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    position: absolute;
    z-index: 999;
  }
  .arrow:hover {
    background-color: #fff;
    color: #777;
  }
  
  .next {
    top: 35%;
    right: 1.5rem;
  }
  .prev {
    top: 35%;
    left: 1.5rem;
  }
  
  hr {
    height: 2px;
    background: white;
    width: 50%;
  }
  .test{
    text-align: center;
    padding: 1.5cm;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font: bold;
    /* background-image: url(../assets/my8.jpg);
    background-size: cover;
    background-position: center; */
  }
  .test hr{
    width: 100%;
  }
  .testimg{
    height: fit-content;
  }
  
  .testimg{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
   
  }
  .testimg img{
    padding: 0.5cm;
  }
  
  .cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .test{
    /* background-color: rgba(255, 145, 0, 0.164); */
}
  
  
  .about{
    padding-bottom: 2cm;
    padding-left: 2cm;
    padding-right:2cm;
    /* background-color: rgba(255, 145, 0, 0.164); */
}
.slider-container .AwesomeSlider {
  border: none !important;
}

.slider-container .AwesomeSlider img {
  border: none !important;
}
.review-card{
  text-align: center;
  width: 100%;
  z-index:0;
  border-radius: 2cm;
  background-color: #f6e3ca6b !important;
}
.car{
  padding:2cm;
  z-index:0;
}
@media (max-width: 767px) {
  .car {
    padding: 0;
  }
}
